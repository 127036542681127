import React from 'react';
import { LogosList } from 'libs/growth-platform-brand-system-v2/src/layouts/LogosList';
import { useScreenSize } from 'libs/growth-platform-brand-system-v2/src/utils/utils';
import { classNames } from '@payfit/growth-platform-brand-system-v2';
import { HeroMinimal } from '@payfit/growth-platform-brand-system-v2/templates/HeroMinimal';
import { graphql, PageProps } from 'gatsby';
import { Hero } from 'libs/growth-platform-brand-system-v2/src/templates/Hero';
import { morphism } from 'morphism';
import { Query } from '../../../types/dato';
import { TemplateBlocksZone } from '../../components/TemplateBlocksZone';
import Layout from '../../layout';
import { HeadWrapper } from '../../layout/HeadWrapper';
import { schemaLogo } from '../../morphismSchema/components/schemaLogoList';
import { schemaBaseTemplate } from '../../morphismSchema/pages/schemaBaseTemplate';
import { schemaHero } from '../../morphismSchema/templates/schemaHero';
import { schemaHeroMinimal } from '../../morphismSchema/templates/schemaHeroMinimal';

export const Head = ({ data, pageContext, location }: any) => {
  return (
    <HeadWrapper
      data={data}
      pageContext={pageContext}
      location={location}
      templateName="ServicePage"
      dataName={'datoCmsServicePage'}
    />
  );
};
export const query = graphql`
  query DatoServicePage($locale: String, $id: String) {
    datoCmsServicePage(locale: $locale, id: { eq: $id }) {
      ...ServicePage
    }
  }
`;

const ServicePage = ({ data, pageContext }: any) => {
  const doc: any = data.datoCmsServicePage;
  const hero = doc.hero[0];
  const screenSize = useScreenSize();
  const { heroLogos, ..._data } = morphism(schemaBaseTemplate, {
    ...doc,
    screenSize,
    pageContext,
  });

  if (!doc) return null;

  let heroData: any;

  if (hero?.__typename === 'DatoCmsHeroMinimal') {
    heroData = morphism(schemaHeroMinimal, hero);
  }
  if (hero?.__typename === 'DatoCmsHero') {
    heroData = morphism(schemaHero, hero);
  }

  return (
    <Layout pageContext={pageContext} data={doc} templateName="ServicePage">
      {hero?.__typename === 'DatoCmsHeroMinimal' && (
        <HeroMinimal {...heroData} className={classNames(doc.breadcrumbsText ? "" : "mt-80 banner-open:mt-[144px]")} />
      )}
      {hero?.__typename === 'DatoCmsHero' &&
        <Hero {...heroData}>
          {heroLogos?.length > 0 && (
            <LogosList
              isAnimated
              isEncapsulated
              logos={morphism(schemaLogo, heroLogos) as any}
              title={
                _data?.hero?.heroAdditionalComponent?.logosListTitle || undefined
              }
              className="mt-64 md:mt-104"
            />
          )}
        </Hero>
      }

      {_data?.blocks.length > 0 && <TemplateBlocksZone {..._data} doc={doc}/>}
    </Layout>
  );
};

export default ServicePage;
